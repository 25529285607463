import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { Provider } from 'react-redux'
import store from './store'

import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

import './css/index.css';
import './css/animista.css';


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
  ,document.getElementById('root')
);
