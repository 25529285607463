import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';


import Animista, { AnimistaTypes } from "react-animista";


import { WaveTitle } from '../../parts';
import { services_data } from '../../../data/services';

import { ActiveWaveHeader } from '../../sections' 

import { gray } from '../../../constants'

import TestImage from './assets/mobile/ios-mobile.jpg'


const verticalPadding = 100

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: gray,
        // paddingTop: verticalPadding,
        // paddingBottom: verticalPadding
    },
    card: {
        padding: "2rem",
        margin: "2rem",
        borderRadius: "2rem", 
    },
    card_link: {

    }
}))


const ServiceCell = ({ service, index }) => {
    const classes = useStyles()

    return (
        <div>
            <Animista type={AnimistaTypes.SLIDE_BCK_BOTTOM} direction="reverse">
                <Card className={`shadow-lg ${classes.card}`}>
                    <Row>
                        <Col md={3}>
                            <Card.Img variant="right" src={TestImage} height={"10rem"}/>
                        </Col>
                        <Col>
                            <Card.Body>
                                <Card.Title>{service.title}</Card.Title>
                                <Card.Text>{"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse elementum nec dolor sed ultrices. Aliquam viverra sollicitudin facilisis. Nulla in luctus nisi. Nulla ut congue elit, ut commodo lorem. Aenean vel massa suscipit dolor elementum ultricies quis at elit. Praesent ante diam, aliquam ut sem eget, feugiat consectetur nunc. Nulla finibus vitae massa nec ultricies. Fusce sed nisl volutpat, cursus neque at, tempor nulla. Pellentesque auctor feugiat est, et pharetra mi ullamcorper hendrerit. Donec eros dolor, efficitur aliquam consequat id, accumsan eu nisl. Sed id lacus vitae erat ornare rutrum. Praesent scelerisque quis velit porttitor dapibus. Proin a erat placerat, sodales quam et, condimentum libero. Nulla vulputate lectus quis metus lobortis lobortis. Maecenas non sodales neque."}</Card.Text>
                                <Card.Link className={classes.card_link}>More</Card.Link>
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>
            </Animista>
        </div>
       
    )
}


const MainServicesScreen = () => {
    const classes = useStyles()

    return (
        <div className={classes.background}>

            <ActiveWaveHeader title={"Our Services"}/>
            {/* <WaveTitle title={"Our Services"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse elementum nec dolor sed ultrices. Aliquam viverra sollicitudin facilisis. Nulla in luctus nisi."}/> */}
            <Container>

                {services_data.map((service, index) => {
                    return <ServiceCell service={service} index={index}/>
                })}


            </Container>
        </div>
    )
}

export default MainServicesScreen;