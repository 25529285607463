import React from 'react';
import { makeStyles } from '@material-ui/core';

import MainView from './basic';

import { WaveTitle, ContactUsSection } from '../../parts';


import { TechnologyView, TechnologyCell } from '../../parts/technology-cell'
import { Container } from 'react-bootstrap';

import AngularIcon from './assets/web/angular-icon.png'
import ReactIcon from './assets/mobile/react-icon.png'
import VueIcon from './assets/web/vue-icon.png'


const useStyles = makeStyles((theme) =>({

}))

const WebDevelopmentScreen = () => {
    const classes = useStyles()

    return(
        <div>
            <WaveTitle title={"Web Development"} description={"Our team builds scalable applications on top of next generation infustructure. "} />
            
            <TechnologyView title={"We keep out team at the front of technolgy through using the newest javascript frameworks"}>
                <TechnologyCell title={"React"} image={ReactIcon} description={""} />
                <TechnologyCell title={"Angular"} image={AngularIcon} description={""}/>
                <TechnologyCell title={"Vue"} image={VueIcon} description={""}/>
            </TechnologyView>
            
            <ContactUsSection />
        </div>
    )
}

export default WebDevelopmentScreen;