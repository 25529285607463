import React from 'react';
import { makeStyles } from '@material-ui/core';

import MainView from './basic';

import BackgroundImage from '../../../assets/services-detail/web.png';

const useStyles = makeStyles((theme) =>({

}))

const APIScreen = () => {
    const classes = useStyles()

    return(
        <MainView title={"API"} description={"Top tier API Development"} backgroundImage={"web.png"}>
            API 
        </MainView>
    )
}

export default APIScreen;