import React from 'react';
import { makeStyles } from '@material-ui/core';

import MainView from './basic';

import BackgroundImage from '../../../assets/services-detail/web.png';

const useStyles = makeStyles((theme) =>({

}))

const ERPScreen = () => {
    const classes = useStyles()

    return(
        <MainView title={"ERP"} description={"Top tier Marketing"} backgroundImage={"web.png"}>
            ERP 
        </MainView>
    )
}

export default ERPScreen;