import React from 'react';

import Animista, { AnimistaTypes } from "react-animista";

import { Container } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';

import gradient from './gradient.jpg'



const height = 300

const useStyles = makeStyles((theme) => ({
    contentView: {
        color: 'white',  
        display: "inline-block", 
        position: 'absolute', 
        top: 0, 
        left: 0, 
        zIndex: 100, 
        paddingTop: 150, 
        width: '100%',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)'
    },
    container: {
        color: 'white',
        height: height + 200
    },
    svg: {
        display: "inline-block",
        position: 'absolute',
        top: height,
        left: 0,
        filter: 'drop-shadow( 5px 10px 3px rgba(0, 0, 0, .15))',
    },
    path: {
        fill: `url(${gradient})`,
    }
}))


const WaveTitle = ({ title, description }) => {
    const classes = useStyles()

    return(
        <div>
            <div className={classes.contentView}>
                <Container>
                    <Animista type={AnimistaTypes.SLIDE_BCK_LEFT} direction="reverse">
                        <h1 className="slide-in-left">{title}</h1>
                        <h5 className="slide-in-left" style={{ width: '70%' }}>{description}</h5>
                    </Animista>
                </Container>
            </div>

            <div style={{ height: height, display: "inline-block", position: 'absolute', top: 0, left: 0,  backgroundImage: "linear-gradient(to right, #36D1DC, #5B86E5)",  width: '100%' }} />

            <div className={classes.container}>
                <svg viewBox="0 0 500 150"  width="100%" height="200" preserveAspectRatio="none" className={classes.svg}>
                    <defs>
                        <linearGradient id="gradient">
                        <stop offset="0%" stop-color="#36D1DC" />
                        <stop offset="100%" stop-color="#5B86E5" />
                        </linearGradient>
                    </defs>
                    <path d="M0.00,49.98 C149.99,150.00 271.49,-49.98 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" fill="url('#gradient')" />
                </svg>
            </div>
        </div>
      
    )
}

export default WaveTitle;