import React from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';


import { gradient, borderRadius } from '../../constants';

const useStyles = makeStyles({
    section: {
        backgroundColor: '#F5F6FA',
        paddingBottom: 100, 
        paddingTop: 100, 
        marginBottomm: 50, 
        marginTop: 50
    },
    button: {
        justifyContent: 'center',
        textAlign: 'center',
        height: 35, 
        borderRadius: 17.5, 
        backgroundImage: gradient,
        color: 'white', 
        fontWeight: 500, 
        fontSize: '12px',
        borderWidth: 0,
        paddingRight: 20, 
        paddingLeft: 20,
        opacity: 1.0,
        // margin: '10 10 10 10',
        bottom: '20px',
        '&:hover': {
            opacity: 0.8,
            color: 'black'
        },
        '&:focus': {
            outline: 'none'
        }
    },
    card: {
        backgroundColor: 'white',
        textAlign: 'center',
        height: '100%',
        borderRadius: borderRadius,
        maxWidth: '350px',
        marginRight: 'auto',
        marginLeft: 'auto',
        transition: 'all .2s ease-in-out',
        '&:hover': {
            transform: 'scale(1.05)'
        },
    },
    col: {
        marginBottom: borderRadius * 2,
    }
})

 
const PayStructureSection = () => {
    const classes = useStyles()

    const ActionButton = ({ title }) => {
        return <button className={`shadow-lg text-capitalize ${classes.button}`} onClick={() => {
            window.open('https://calendly.com/sparrow-development/30min', "_blank")
        }}>{title}</button>
    }



    return (
        <section className={classes.section}>
            <Container fluid style={{maxWidth: '1000px'}}>
                <div style={{ textAlign: 'center', paddingBottom: 50 }}>
                    <h2 className="mb-3" style={{color: 'rbga(0, 0, 0, 0.9)'}}>Pricing that fits you</h2>
                    <h5 className="text-muted font-weight-light">Paid by milestone or hourly.</h5>
                </div>
            
                <Row className="justify-content-center">
                    <Col sm={10} md={4} className={classes.col}>
                        <Card className={`shadow-lg ${classes.card}`}>
                            <Card.Body>
                                <p style={{ color: '#42A7F7', fontSize: '18px' }}>Two Consultations</p>
                                <Card.Text>
                                    <span style={{fontSize: '20px'}}>$</span><span style={{fontSize: '40px'}}>0</span><span className="text-muted">/meeting</span>
                                    <p className="text-muted">Introductory offer</p>
                                </Card.Text>
                                <ActionButton title={"SCHEDULE"}/>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={10} md={4} className={classes.col}>
                        <Card className={`shadow-lg ${classes.card}`}>
                            <Card.Body>
                            <p style={{ color: '#42A7F7', fontSize: '18px' }}>Business Consulting</p>
                                <Card.Text>
                                    <span style={{fontSize: '20px'}}>$</span><span style={{fontSize: '40px'}}>250*</span><span className="text-muted">/hour</span>
                                    <p className="text-muted">Path to clarity</p>
                                </Card.Text>
                                <ActionButton title={"SIGN UP NOW"}/>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={10} md={4} className={classes.col}>
                      <Card className={`shadow-lg ${classes.card}`}>
                            <Card.Body>
                            <p style={{ color: '#42A7F7', fontSize: '18px' }}>Software Services</p>
                                <Card.Text>
                                    <span style={{ fontSize: '40px'}}>TBD</span>
                                    <p className="text-muted">Give us a call to quote</p>
                                </Card.Text>
                                <ActionButton title={"GET A QUOTE"}/>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PayStructureSection;