import React from 'react';

import { Container, Row, Col, Jumbotron } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';
import { industries_data } from '../../data/industries';

import { TopTitleSection, ContactUsSection  } from '../parts';
const useStyles = makeStyles((theme) => ({
    topSection: {
        backgroundImage: "linear-gradient(to top right, #36D1DC, #5B86E5)", 
        paddingTop: 150 + 30, 
        paddingBottom: 150,
        color: 'white'
    }

}))


const IndustriesScreen = () => {
    const classes = useStyles()
    return (
        <>
            <TopTitleSection title={"Industries We Love"} description={"Resources exquisite set arranging moonlight sex him household had. Months had too ham cousin remove far spirit. She procuring the why performed continual improving. Civil songs so large shade in cause. Lady an mr here must neat sold. Children greatest ye extended delicate of. No elderly passage earnest as in removed winding or."}/>

        {
            industries_data.map((service) => {
                return(
                    <h1> {service.title} </h1>
                );

            })
        }

        <ContactUsSection />
        </>
    
    );
}
 
export default IndustriesScreen;