import React from 'react';
import { Container, Jumbotron, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundImage: "linear-gradient(to top right, #36D1DC, #5B86E5)", 
        paddingTop: 150, 
        paddingBottom: 150,
        color: 'white',
        marginBottom: 0,
    },
    title: {
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
        fontSize: '2rem'
    },
    button: {
        transition: 'all .2s ease-in-out',
        '&:hover': {
            transform: 'scale(1.025)'
        }
    }
}))

const ContactUsSection = () => {
    const classes = useStyles()

    return(
        <Jumbotron fluid className={classes.background}>
            <Container>
                <h1 className={classes.title}>Start your journey with us.</h1>       
                <Button variant="light" as={Link} to='/contact-us' className={`${classes.button} shadow-lg`}>
                    Contact Us
                </Button>
            </Container>
        </Jumbotron>
    )
}

export default ContactUsSection;