import React from 'react';
import {  } from 'formik';

import { Container } from 'react-bootstrap';





const InternshipApplicationScreen = () => {

    return(
        <Container>
            <h1>Apply</h1>


        </Container>
    );
    
}

export default InternshipApplicationScreen;