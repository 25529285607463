
import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'


class ErrorScreen extends Component {

    render() {
        return(
            <Container>
                <Row  
                    className="justify-content-center"
                    style={{
                        marginTop: 300,
                        marginBottom: 300
                    }}>

                    <Col sm={10} md={7} lg={6}>
                        <h1 style={{textAlign: "center"}}>Hmm... I'm not sure where you're tring to go?</h1>
    
                        <p style={{
                            textAlign: "center",
                            fontSize: 20
                            }}>
                            <Link to="/">
                                Go to Home Page
                            </Link>
                        </p>
    
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default ErrorScreen;