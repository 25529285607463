import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';


// Basic Screens
import ErrorScreen from './components/screens/error';

// Main Screens
import HomeScreen from './components/screens/home';
import ContactUsScreen from './components/screens/contact-us'
import JobsScreen from './components/screens/jobs'
import IntershipApplicationScreen from './components/screens/internship-application'


import Footer from './components/general/footer'; 
import SDNavBar from './components/general/nav';
import InternshipApplicationScreen from './components/screens/internship-application';
import JobDescriptionScreen from './components/screens/job-description';


import IndustriesScreen from './components/screens/industries';
import IndustriesDetailScreen from './components/screens/industries-detail';

import TeamScreen from './components/screens/team';

import { 
  MainServicesScreen,
  MobileDevelopmentScreen,
  WebDevelopmentScreen,
  UIUXScreen,
  MarketingScreen, 
  APIScreen, 
  ERPScreen,
  DataServicesScreen
 } from './components/screens/services/index'


class App extends Component {

  render() {
    return(

      <Router>
        <SDNavBar />
        <div style={{minHeight: 'calc(100vh - 60px)'}}>

        <Switch>
            <Route exact path='/' component={HomeScreen} />
            <Route exact path='/contact-us' component={ContactUsScreen} />

            {/* New Screens */}
            <Route exact path='/careers' component={JobsScreen} />
            <Route exact path="/careers/internship-application" component={InternshipApplicationScreen} />
            <Route exact path="/careers/job-description:id" component={JobDescriptionScreen} />
 

            {/* Services */}
            <Route exact path='/services' component={MainServicesScreen}/>
            <Route exact path='/services/mobile-development' component={MobileDevelopmentScreen}/>
            <Route exact path='/services/web-development' component={WebDevelopmentScreen}/>
            <Route exact path='/services/ui-ux' component={UIUXScreen}/>
            <Route exact path='/services/marketing' component={MarketingScreen}/>
            <Route exact path='/services/api' component={APIScreen}/>
            <Route exact path='/services/erp' component={ERPScreen}/>
            <Route exact path='/services/data' component={DataServicesScreen}/>
            




            <Route exact path='/industries' component={IndustriesScreen}/>
            <Route exact path='/industries/:id' children={<IndustriesDetailScreen />}/>

            <Route exact path='/team' children={<TeamScreen />}/>


            <Route path='*' component={HomeScreen} />
        </Switch>
        </div>
        <Footer />
      </Router>
    );
  }
}
export default App;
