import React from 'react';

import { Container, Jumbotron } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    topSection: {
        backgroundImage: "linear-gradient(to top right, #36D1DC, #5B86E5)", 
        paddingTop: 150 + 30, 
        paddingBottom: 150,
        color: 'white',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15)',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
    },
    container: {
        display: 'inline-block',
        position: 'relative',
        width: '100%',
        paddingBottom: '100%',
        verticalAlign: 'middle',
        overflow: 'hidden'
    }

}))


const TopTitleSection = ({ title, description }) => {
    const classes = useStyles()

    return(
        <section>
            <Jumbotron fluid className={classes.topSection}>
                <Container>
                    <h1>{title}</h1>
                    <p>{description}</p>
                </Container>
            </Jumbotron>
        </section>
    )
}

export default TopTitleSection;