import React from 'react';
import { Container, Col, Row, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import { gray, minHeight } from '../../constants'

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: gray,
        minHeight: 'calc(100vh - 60px)'// This nee
    },
    indeedLink: {
        
    }
}))


// This should probably be pulled from Firebase
const JobsScreen = () => {
    const classes = useStyles()


    return( 
        <div className={classes.background}>
            <Container>

            <div style={{height: 150}} />

            <Row className="justify-content-center">
                <Col sm={10} md={8} lg={6} className="mb-5">
                    <h2 className="text-muted"> Jobs </h2>
                    <p>At Sparrow Development we are always looking for exceptional new tallent. If you would like to join our team. Submit your resume and we will be in touch.</p>
                    <p>Jobs can be found on our Indeed and LinkedIn Pages.</p>
                
                    <a href="https://www.linkedin.com/company/sparrow-development/" class="fa fa-linkedin mr-3"></a>
                    <a href="https://www.indeedjobs.com/sparrow-development/"><b>Indeed</b></a>



                </Col>
                <Col sm={10} md={8} lg={6}>
                    <h2 className="text-muted"> { new Date().getFullYear() + 1 } Internships </h2>
                    <p>Are you currently in college or recently graduated? If so you can apply to Sparrow Develpments Intership program where you will be immersed in top tier development on a daily basis.</p>
                    <p>Alaskan students are prefered due to the companies alaska headquarters.</p>
                    <p className="text-muted">Lmited to 2 individuals</p>
                        <p className="text-muted" style={{ margin: 0, fontSize: "1.0rem" }}><u>Please Inculde</u></p>
                        <ul style={{ fontSize: ".75rem", listStyle: "none"}}>
                            <li>Name</li>
                            <li>Contact Information</li>
                            <li>Resume</li>
                            <li>Cover Letter (Recommended)</li>
                        </ul>
                
                        <Button
                            onClick={() => {
                                window.location.href = `mailto:jobs@sparrowdevelopment.net?subject=Internship Application`;
                            }}> 
                            Apply 
                        </Button>
                    
                </Col>


                {/* This is for future Additions */}

                {/* <Col sm={10} md={8} lg={6}>
                <h2 className="text-muted mb-4" style={{textAlign: 'center'}}>Available Positions</h2>
                <Card className="rounded shadow-lg mb-4" style={{height:  100, width: '100%'}}>
                    <Card.Body>
                    <Card.Title> React Software Engineer </Card.Title>
                    </Card.Body>
                
                </Card>
            
                </Col> */}


            </Row>

            </Container>
        </div>     
    );
}

export default JobsScreen;