import React from 'react';

import Particles from 'react-particles-js';

class ParticalsSection extends React.Component {
    render() {
        return(
            <section style={{ height: 300, backgroundColor: 'black'}}>
                <Particles height={'100%'} width={"100%"} />
            </section>
        )
    }
   
}


export default ParticalsSection