import React from 'react';
import { useParams } from "react-router-dom";
import { Jumbotron, Container, Row, Col } from 'react-bootstrap';
import MobileImage from '../../assets/services-detail/mobile.jpeg';
import ServerImage from '../../assets/services-detail/servers.png';
import WebImage from '../../assets/services-detail/web.png';



import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles({
    jumbo: {
        background:`url(${MobileImage}) no-repeat center center`,
        backgroundSize: 'cover',
        height: 500,
        color: 'white',
        textAlign: 'center',
        boxShadow: '0px 0px 10px black',
        position:'relative',
        display: 'flex'
    },
    jumboText: {
        backgroundColor: 'rgba(0,0,0, 0.80)', 
        borderRadius: '10px', 
        bottom: '30px', 
        padding: '15px', 
        position: 'absolute', 
        right: '30px', 
        left: '30px',
    }
})

function IndustriesDetailScreen() {
    let { id } = useParams();
    const classes = useStyles()

    return(
      <div>
            <Jumbotron className={classes.jumbo}>
                <div className={classes.jumboText}>
                    <h2>Mobile Development</h2>
                </div>
            </Jumbotron>
            <Container>
            <div style={{height: 20}}/>

            <h2 className="text-muted mt-3" style={{textAlign: 'center'}}>We can build anything you can imagine</h2>
            <p className="mb-5" style={{textAlign: 'center'}}s>
                Awnser a few questions about what you would like to build and our engineers will put you on the right track.
            </p>

            <Row className="justify-content-center">
                    <Col style={{maxWidth: '600px', width: '80%'}}>
                    <div style={{height: 200}}>
                    <h2 className="text-muted mt-3">Native iOS (Swift)</h2>
                    <p>Testing</p>
                        </div>

                    </Col>

                </Row>
  
        
            <div style={{ maxWidth: '600px', width: '80%'}}>
                

                <div style={{height: 200}}>

                <h2 className="text-muted mt-3">Native Android (Kotline)</h2>
                <p>Testing</p>
                </div>
                <div style={{height: 200}}>

                <h2 className="text-muted mt-3">React Native</h2>
                <p>Testing</p>
                    </div>
                <h2 className="text-muted mt-3">Flutter</h2>
                <p>Testing</p>

            </div>

            </Container>
         
      </div>
    );

}
 
export default IndustriesDetailScreen;