import React from 'react';
import { Container, Col, Row, Image } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';


import { borderRadius } from '../../constants';

const useStyles = makeStyles({
    container: {
        borderRadius: borderRadius, 
        paddingBottom: 50, 
        paddingTop: 50, 
        marginBottom: 50,
        marginTop: 50,
        backgroundColor: 'black', 
        color: 'white', 
        textAlign: 'center', 
        maxWidth: '90%' 
    },
    title: {
        paddingBottom: 20
    },
    cellImage: {
        width: 100, 
        height: 100, 
        marginBottom: 20,
        objectFit: 'cover'
    }
})

const TechnologyView = ({title, children}) => {
    const classes = useStyles()
    return(
        <Container className={`shadow-lg ${classes.container}`}>
                <h5 className={classes.title}>{title}</h5>
                <Row className="justify-content-center">
                    {children}  
                </Row>
        </Container>
    )
}

const TechnologyCell = ({title, description, image}) => {
    return (
        <Col sm={8} md={4} className="mb-5">
            <Image width={100} height={100} src={image} style={{ marginBottom: 20, objectFit: 'cover'}}/>
            <h5>{title}</h5>
            <p>{description}</p>
        </Col>
    )
}

export  {
    TechnologyCell, 
    TechnologyView
}