export const services_data = [
    { 
        "title": "Mobile Development",
        "subtitle": "Native iOS, Native Android, React & Flutter",
        "short_description": "Choose a native or cross-platform solution to best suit your companies needs particular needs and requirements.",
        "image": require("../assets/services/mobile.jpg"),
        "link": "/services/mobile-development",
        "active": true
    },
    { 
        "title": "Web Development",
        "subtitle": "React, Angular & Vue",
        "short_description": "Our team will help you select the best web framework available so your website can accommodate a global audience.",
        "image": require("../assets/services/web.jpg"),
        "link": "/services/web-development",
        "active": true
    }, 
    { 
        "title": "Marketing",
        "subtitle": "Facebook, Instagram, Google, Apple",
        "short_description": "",
        "image": require("../assets/services/web.jpg"),
        "link": "/services/marketing",
        "active": false
    }, 
    { 
        "title": "ERP",
        "subtitle": "",
        "short_description": "",
        "image": require("../assets/services/data.jpg"),
        "link": "/services/erp",
        "active": false
    }, 
    {
        "title": "API",
        "subtitle": "Node, PHP, Python, Ruby",
        "short_description": "Design and deploy an industry grade API to handle thousands of requests per minute. Or something smaller for your companies internal use.",
        "image": require("../assets/services/data.jpg"),
        "link": "/services/api",
        "active": true
    },
    { 
        "title": "Data Services",
        "subtitle": "SQL & NoSQL Databases",
        "short_description": "Convert, update, and migrate your companies data. Run analytics on your data sets to find new insights into your companies operations and sales.",
        "image": require("../assets/services/servers.jpg"),
        "link": "/services/data",
        "active": true
    },
    { 
        "title": "Consulting",
        "short_description": "Talk with our expert consults to determine how your company can best improve its technology infrastructure and increase its efficiency.",
        "image": require("../assets/services/consulting.jpg"),
        "link": "/services/consulting",
        "active": true
    }
]
