import React,  { Component } from 'react';
import { Navbar, Nav, Image, NavDropdown, Dropdown } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { services_data } from '../../data/services';
import { industries_data } from '../../data/industries';

const useStyles = makeStyles({
    navbar: {
        backgroundColor: 'rgba(0,0,0, 0.70)'
    },
    navLink: {
        color: 'white',
        fontWeight: '500'
    },
  });



const SDNavBar = () => {
    const  classes = useStyles();

    // Get the active nav key
    const servicesList = ["Web Development", "Mobile Development", "UI/UX Design", "Digital Marketing", "Data Analytics", "CRM"]

    return(
        <Navbar expand="md" fixed="top" variant="dark" className={classes.navbar} >
        <Link to="/">
            <Navbar.Brand style={{color: 'white'}}>
                <Image 
                    className="d-inline-block align-top mr-3"
                    src={require("../../assets/logo/logo_sm.png")}
                    height={32}
                    width={32} 
                    style={{backgroundSize: 'contain'}}
                />
                <b> Sparrow Development </b>
            </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav>


                {/* <NavDropdown title="Industries">
                    <Link to="/services">
                        <NavDropdown.Item as={Link} to="/industries">All Industries</NavDropdown.Item>
                    </Link>
                    <NavDropdown.Divider />

                  {
                    industries_data.map((industry) => {
                        if(industry.active === true) {
                            return(
                                <NavDropdown.Item as={Link} to={industry.link} key={industry.link}>{industry.title}</NavDropdown.Item>
                            );
                        }
                    })
                  }
                </NavDropdown> */}

                {/* <NavDropdown title="Services">
                    <Link to="/services">
                        <NavDropdown.Item as={Link} to="/services">All Services</NavDropdown.Item>
                    </Link>
                    <NavDropdown.Divider />

                    {
                        services_data.map((service) => {
                            if(service.active === true) {
                                return(
                                    <NavDropdown.Item as={Link} to={service.link} key={service.link}>{service.title}</NavDropdown.Item>
                                );
                            }
                        })
                    }
                </NavDropdown> */}

                <Nav.Item>
                    {/* <Nav.Link as={Link} to="/team" className={classes.navLink}> Team </Nav.Link> */}
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/careers" className={classes.navLink}> Careers </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/contact-us" className={classes.navLink}> Contact Us </Nav.Link>
                </Nav.Item>                
            </Nav>
        </Navbar.Collapse>
        </Navbar>
    );
}

export default SDNavBar;