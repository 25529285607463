import React, { Component } from 'react';
import { makeStyles, Modal, Backdrop, Fade } from '@material-ui/core'

import { InlineWidget } from "react-calendly";

import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';


import Particles from 'react-particles-js';


import { 
    Formik, 
    useFormik,
    Form as FormikForm
} from 'formik';

import { Form as BSForm, Card, Container } from 'react-bootstrap';

import axios from 'axios';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
    background: {
        minHeight: 'calc(100vh - 60px)'
    },
    title: {
        textAlign: 'center',
        marginTop: '10px',
        marginBottom: '20px',
        fontWeight: '500',
        fontSize: '30px',
        color: 'darkgray',
    },
    card: {
        padding: '20px',
        minWidth: '350px',
        boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center',
        borderRadius: '20px',
        paddingTop: '30px',
        paddingBottom: '30px',
    },
    submitButton: {
        boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
        backgroundColor: '#4CADE0',
        borderRadius: '18px',
        height: '36px',
        color: 'white',
        fontWeight: 700,
        borderWidth: 0,
        '&:hover': {
            backgroundColor: '#5A89E5',
        },
        '&:focus': {
            outline: 0,
        }
    },
    errorMessage: {
        color: 'red',
        fontWeight: 700,
        fontSize: '10px',
        padding: 0,
        margin: 0,
        textAlign: 'left'
    },
    contactItemsBackground: {
        minHeight: '400px', 
        textAlign: 'center', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',  
        marginBottom: '10px',
        [theme.breakpoints.up('md')]: {
            marginBottom: '60px',
        },
    },
    contactItemBackground: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', 
        borderRadius: '10px', 
        padding: '25px 15px 25px 15px',
        marginTop: '20px',
        '&:hover': {
            backgroundColor: '#5A89E5'
        },
    },
    contactOurTeamTitle: {
        color: 'white', 
        textAlign: 'center', 
        paddingTop: '75px', 
        paddingBottom: '0px',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

        [theme.breakpoints.up('md')]: {
            paddingTop: '100px', 
            paddingBottom: '50px',
        },
    },

  }));


const ContactUsSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Name is too short")
        .max(25, "Name it too long")
        .required('Required'),
    email: Yup.string()
        .min(5, 'A valid email must have at leas 5 charaters')
        .max(50, 'Please enter a shorter email.')
        .required('Required'),
    number: Yup.string()
        .min(7, 'Not a valid phone number')
        .max(15, 'Please enter a valid phone number.')
        .required('Required'),
    message: Yup.string()  
        .max(1000, 'Please keep your message shorter than 1000 charaters')
        .required('Required'),
        
})

// Not being used at the moment
function validateEmail(value) {
    let error;
    if (!value) {
        error = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = 'Invalid email address';
    }
       return error;
}

const ContactUsFrom = () => {
    const classes = useStyles()

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const ErrorMessage = ({ message }) => {
        return <p className={classes.errorMessage}>{message}</p>
    }


    return(
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                <div className={classes.paper}>
                    <h2 className="text-muted">Form Submitted</h2>
                    <p className="text-muted">Thank you for your inquiry our team will get back with you shortly.</p>
                </div>
                </Fade>
            </Modal>

            <Formik
                initialValues = {{ name: '', email: '', number: '', message: '',preferred_communication: '' }}
                validationSchema = { ContactUsSchema }
                onSubmit = { async (values, { setSubmitting, resetForm }) => {
                    await axios({
                        method: 'post',
                        url: 'https://us-central1-sparrow-development-d73a1.cloudfunctions.net/api/contact-us',
                        data: values
                    }).then(() => {
                        handleOpen()
                        resetForm({ name: '', email: '', number: '', message: '', preferred_communication: '' })
                        setSubmitting(false);
                    })
                }}>
                {({ values, handleChange, handleBlur, isValidating, errors, touched }) => (      
                 <FormikForm>
                    <Card className={classes.card}>
                        <Card.Title className={classes.title}>Schedule Meeting</Card.Title>
                        <BSForm.Group>
                            <BSForm.Control 
                                name="name"
                                placeholder="Full Name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                                {errors.name && touched.name ? <ErrorMessage message={errors.message}/> : null }
                        </BSForm.Group>

                        <BSForm.Group>
                            <BSForm.Control 
                                name="email"
                                placeholder="Email"
                                type="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                validate={validateEmail}
                            />
                            {errors.email && touched.email ? <ErrorMessage message={errors.message}/> : null }
                        </BSForm.Group>

                        <BSForm.Group>
                            <BSForm.Control 
                                name="number"
                                placeholder="Number"
                                type="tel"
                                value={values.number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.number && touched.number ? <ErrorMessage message={errors.message}/> : null }
                        </BSForm.Group>

                        <BSForm.Group>
                            <BSForm.Control 
                                name="message"
                                placeholder="Message/Availability"
                                as="textarea"
                                type="text"
                                value={values.message}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                style={{maxHeight: '175px', minHeight: '60px'}}
                            />
                            {errors.message && touched.message ? <ErrorMessage message={errors.message}/> : null }
                        </BSForm.Group>
                        
                        <button type="submit" className={classes.submitButton}>
                            Submit
                        </button>
                     </Card>
                </FormikForm>
            )}
            </Formik>     

        </div>
        
    );
}

const ContactUsScreen = () => {    
    const classes = useStyles()

    return( 
        <div style={{ height: 'calc(100vh - 60px)', backgroundImage: "linear-gradient(to top right, #36D1DC, #5B86E5)" }}>
            <Particles height="calc(100vh - 60px)" />
            <div 
                style={{ 
                    height: 'calc(100vh - 60px)', 
                    width: '100%',
                    position: 'absolute',
                    paddingTop: '10vh',
                    top: 0,
                    left: 0,
                }}>

                    <Container>
                     <InlineWidget url="https://calendly.com/sparrow-development" styles={{ height: 'calc(80vh)'}}/>

                    </Container>
                
            </div>
        </div>
    );
}

export default ContactUsScreen;