import React from 'react';
import { makeStyles } from '@material-ui/core';

import MainView from './basic';

const useStyles = makeStyles((theme) =>({

}))

const DataServicesScreen = () => {
    const classes = useStyles()

    return(
        <MainView title={"UI & UX"} description={"Top tier web development"} backgroundImage={"web.png"}>
            Data Services
        </MainView>
    )
}

export default DataServicesScreen;