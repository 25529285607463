import React, { Component } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';

import { TopTitleSection } from '../parts'


class TeamScreen extends Component {

    team = [
        { name: "Aaron Morse", position: "CEO, Lead Software Engineer", image: '' }
    ]

    partners = [
        { name: '', description: '', industry: 'ERP'},
        { name: '', description: '', industry: 'marketing'},

    ]

    // Team Member Cell
    teamMemberCell({ name, description, position, image }) {
        return(
            <Col>
                <Card>
                    <Card.Body>
                        <Card.Title>{name}</Card.Title>
                        <Card.Subtitle>{position}</Card.Subtitle>
                        <Card.Text>{description}</Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        );
    }

    // Partner Section
    partnerSection({ name, description, position, image }) {
        return (
            <Card>

            </Card>
        )
    }

    SectionComponent({ title, children}) {

        return(
            <Container styles={{ marginTop: '50px'}}>
                <h1>{title}</h1>
                {children}
            </Container>
        )
    }

    render() {
        return(
            <>
                <TopTitleSection title="Our Team" description="Behaviour we improving at something to. Evil true high lady roof men had open. To projection considered it precaution an melancholy or. Wound young you thing worse along being ham. Dissimilar of favourable solicitude if sympathize middletons at. Forfeited up if disposing perfectly in an eagerness perceived necessary. Belonging sir curiosity discovery extremity yet forfeited prevailed own off. Travelling by introduced of mr terminated. Knew as miss my high hope quit. In curiosity shameless dependent knowledge up." />

                <this.SectionComponent title="Excutive Team">
                    <Row>
                        {
                            this.team.map((member) => {
                                return this.teamMemberCell(member)
                            })
                        }
                    </Row>
                </this.SectionComponent>


                <this.SectionComponent title="Partners">
                    <Row>
                        {
                            this.team.map((member) => {
                                return this.teamMemberCell(member)
                            })
                        }
                    </Row>
                </this.SectionComponent>
            </>
        );
    }
}


export default TeamScreen;