import React from 'react';

import { makeStyles } from '@material-ui/core';

import { ServicesTopBar, ContactUsSection } from '../../parts';

const useStyles = makeStyles((theme) => ({

}))

const ServicesBasicOutline = ({ title, description, backgroundImage, children }) => {
    const classes = useStyles()
    console.log("Backgroun in Basic", backgroundImage)
    return(
        <div>
            <ServicesTopBar title={title} description={description} backgroundImage={backgroundImage}/>
            {children}
            <div style={{ height: 400 }}/>
            <ContactUsSection />
        </div>
    )
}


export default ServicesBasicOutline;