import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Row, Col, Container, Card, Image } from 'react-bootstrap';

import { WaveTitle, ContactUsSection } from '../../parts';

import { borderRadius } from '../../../constants'

import MainView from './basic';

import { TechnologyView, TechnologyCell } from '../../parts/technology-cell'


// Image imports
import iOSMobileImage from './assets/mobile/ios-mobile.jpg'
import AndroidMobileImage from './assets/mobile/android-mobile.png'

import iPhoneImage from './assets/mobile/iphone.png'


import ReactIcon from './assets/mobile/react-icon.png'
import FlutterIcon from './assets/mobile/flutter-icon.png'
import SwiftIcon from './assets/mobile/swift-icon.png'


const useStyles = makeStyles((theme) =>({
    platfromCard: {
        borderRadius: borderRadius,
    },
    platfromCardImage: {
        borderRadius: borderRadius,
    }
}))

const MobileDevelopmentScreen = () => {
    const classes = useStyles() 

    // console.log("Image: ", typeof(BackgroundImage), BackgroundImage)

    

    return(
        <div>
            {/* <Wave2 /> */}
            <WaveTitle title={"Mobile Development"} description={"Mobile is the new way to connect with millions of individuals accross the globe."}/>

            <Container> 
                <h1>Mobile</h1>
                <p>Mobile is the new way to connect with millions of individuals accross the globe.</p>

                <p>Our team is equipted to handle anything your team can imagine by using the top mobile frameworks avalible.</p>
            </Container>
    
            <TechnologyView>
                <TechnologyCell title="Native" image={SwiftIcon} description="Using native code will make your app more effiecnt an faster running. But comes at increased expsense of mangaing two apps."/>
                <TechnologyCell title="Flutter" image={FlutterIcon} description="Cross platfrom development"/>
                <TechnologyCell title="React" image={ReactIcon} description="Cross platfrom development"/>
            </TechnologyView>


            <Container style={{ paddingBottom: 100, paddingTop: 100 }}>
                <h1 className="mb-4" style={{fontFamily: "Sans-serif"}}>Over 3 Billion Devices</h1>

                <Row>
                    <Col>
                        <Card className="shadow-lg text-muted" style={{ borderRadius: borderRadius, height: '100%'  }}>
                            <Card.Img src={iOSMobileImage} style={{ borderRadius: borderRadius, height: '400px', objectFit: 'cover' }}/>
                            <Card.Body>
                                <Card.Title>iOS</Card.Title>
                                <Card.Subtitle>Build a powerful dynamic application running on apples iPhone, iPads, Watches and more.</Card.Subtitle>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="shadow-lg text-muted" style={{ borderRadius: borderRadius, height: '100%' }}>
                        <Card.Img src={AndroidMobileImage} style={{ borderRadius: borderRadius, height: '400px', objectFit: 'cover' }}/>
                            <Card.Body>
                                <Card.Title>{"Android"}</Card.Title>
                                <Card.Subtitle>{"subtitle"}</Card.Subtitle>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <ContactUsSection />
        </div>
    )
}

export default MobileDevelopmentScreen;