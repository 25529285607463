import React from 'react';

import { Container, Jumbotron } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';

import BImage from '../../assets/services-detail/web.png'


const useStyles = makeStyles((theme) => ({
    image: {
        objectFit: 'cover',
        height: 500,
        color: 'white',
        textAlign: 'center',
        boxShadow: '0px 0px 10px black',
        position:'relative',
        display: 'flex',
        width: '100vw',
        zIndex: -1
    },
    title: {
        backgroundColor: 'rgba(0,0,0, 0.80)', 
        borderRadius: '8px', 
        top: '350px', 
        padding: '10px', 
        position: 'absolute', 
        right: '30px', 
        left: '30px',
        textAlign: 'center',
        color: 'white'
    }
}))


const ServicesTopBar = ({ title, description, backgroundImage }) => {
    const classes = useStyles()
    return(
        <section>
            <img src={require(`../../assets/services-detail/${backgroundImage}`)} className={classes.image}/>
            <h1 className={classes.title}>{title}</h1>

    
         
        </section>
    )
}

export default ServicesTopBar;