import React, { Component } from "react"
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { Container, Row, Col } from "react-bootstrap"


const apiKey = 'AIzaSyDI8MQDMyv8CJfbsMiVhomKALLu6Be5JX4'

const MapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%`, borderRadius: '10px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19'}} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) =>
  <GoogleMap
    defaultZoom={10}
    defaultCenter={{ lat: 61.2181, lng: -149.9003 }}>
    { props.isMarkerShown && <Marker position={{ lat: 61.2181, lng: -149.9003 }} onClick={props.onMarkerClick }/>}
  </GoogleMap>
)

class CompanyLoctionSection extends Component {
  state = {
    isMarkerShown: false,
  }

  componentDidMount() {
    this.delayedShowMarker()
  }

  delayedShowMarker = () => {
    setTimeout(() => {
      this.setState({ isMarkerShown: true })
    }, 3000)
  }

  handleMarkerClick = () => {
    this.setState({ isMarkerShown: false })
    this.delayedShowMarker()
  }

  render() {
    return (
        <section style={{
            paddingTop: 100, 
            paddingBottom: 100
        }}>
            <Container>
                <Row className="justify-content-center">
                    <Col style={{   
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            padding: '10px'
                        }}
                        md={8} lg={6}>
                        <div>
                            <h1 className="text-muted">Alaskan Software</h1>
                            <p>Sparrow Development is based in Anchorage, Alaska, and will soon expand to Fairbanks. Our team is comprised of national talent and working to inspire innovation in Alaska and accros the US.</p>
                        </div>
                    </Col>
                    <Col md={9} lg={6}>
                        <MapComponent />
                    </Col> 
                </Row>
            </Container>
        </section>
    )
  }
}


export default CompanyLoctionSection;