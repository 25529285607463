export const navHeight = '6rem'
export const footerHeight = '6rem'

export const borderRadius = 15

export const gradient =  "linear-gradient(to right, #36D1DC, #5B86E5)"
export const gray = "#F5F6FA"



// doesnt seem to work
export var minHeight = (includeNav = false) => {
    return (includeNav === true) ? `calc(100vh - ${navHeight}` : `calc(100vh - ${navHeight} - ${footerHeight})`
}