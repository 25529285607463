import React from 'react';
import { Card, Col, Row, Container, Jumbotron, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import AnchorageImage from '../../assets/anchorage_3.jpeg';
import { makeStyles } from '@material-ui/core'

import { services_data } from '../../data/services';

import { ContactUsSection } from '../parts'

import { borderRadius } from '../../constants'

import { CompanyLoctionSection, ActiveWaveHeader, ParticalsSection } from '../sections'

import PayStructureSection from '../sections/pay-structure'



import Animista, { AnimistaTypes } from "react-animista";


// Images/Icons 
import ConsultingIcon from './home-screen-assets/consult.png'
import DesignIcon from './home-screen-assets/design.png'
import DeployIcon from './home-screen-assets/deploy.png'
import ImplementIcon from './home-screen-assets/implement.png'
import TestIcon from './home-screen-assets/test.png'

const useStyles = makeStyles((theme) => ({
    jumbo: {
        background:`url(${AnchorageImage}) no-repeat center center`,
        backgroundSize: 'cover',
        height: 600,
        color: 'white',
        textAlign: 'center',
        boxShadow: '0px 0px 10px black',
        position:'relative',
        display: 'flex'
    },
    jumboText: {
        backgroundColor: 'rgba(0,0,0, 0.50)', 
        borderRadius: '10px', 
        bottom: '30px', 
        padding: '10px', 
        position: 'absolute', 
        right: '30px', 
        left: '30px'
    },
    doMore: {
        backgroundImage: 'linear-gradient(to right, #36D1DC, #5B86E5)',
        height: '400px',
        width: '100vw',
        color: 'white',
        textAlign: 'center',
        position: 'absolute',
        left: 0,
        transformOrigin: 'bottom right',
        transform: 'skewY(-4deg)',
        zIndex: -1
    },
    doMore1: {
        backgroundImage: 'linear-gradient(to right, #36D1DC, #5B86E5)',
        height: '400px',
        width: '100vw',
        color: 'white',
        textAlign: 'center',
        position: 'absolute',
        left: 0,
    },
    card: {
        borderRadius: borderRadius,
        overflow: 'hidden',
        transition: 'all .2s ease-in-out',
        '&:hover': {
            transform: 'scale(1.025)'
        }
    },
    cardImage: {
        height: '100%', 
        objectFit: 'cover',
        borderRadius: 0,
    }
}))




const HomeScreen = () => {
    const classes = useStyles()


    function importImage(path) {
        const image = require(path)
        return(
            <Card.Img src={image} style={{height: '100%', objectFit: 'cover' }}/>
        );
    }

    
    function serviceCell(data, index) {
        const side = (index % 2 === 0 ) ? {order: 'last'} : {order: 'first'}

        return(
            <Animista type={AnimistaTypes.SLIDE_BCK_BOTTOM} direction="reverse" duration="0.5s">
                <Col xs={12} className="mt-4">
                    {/* <Link style={{ textDecoration: 'none', color: 'black' }} to={data.link}>  */}
                        <Card className={`shadow h-100 ${classes.card}`}>
                            <Row>
                                <Col sm={12} md={5} lg={4} className={side}>
                                    <Card.Img src={data.image} className={classes.cardImage}/>
                                </Col>
                                <Col >
                                <Card.Body>
                                    <Card.Title>{data.title}</Card.Title>

                                    <Card.Subtitle className="text-muted mb-1">{data.subtitle}</Card.Subtitle>
                                    <Card.Text>{data.short_description}</Card.Text>
                                </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    {/* </Link> */}
                </Col>            
            </Animista>

        );
    }

   
        return(
            <>
                {/* <Jumbotron className={classes.jumbo}>
            
                    <div className={classes.jumboText}>
                        <h2> Alaska's Premiere Software Development Firm </h2>
                        <p> All the services you need in one place. Consulting, Mobile, Web, Analytics.</p>
                    </div>
                </Jumbotron> */}

                <ActiveWaveHeader 
                    title="Sparrow Development"
                    subtitle=""
                    backgroundColor="white"
                />
                {/* <ParticalsSection /> */}

                <Container style={{maxWidth: '800px', width: '80%'}}>
            
                <div className="text-muted" style={{textAlign: 'center',  paddingTop: 80, paddingBottom: 100 }}>
                    <h1 className="mb-3">About Us</h1>
                    <p>Sparrow Development is a team of Silicon Valley Tier Engineers, Designers, and Marketers. We are working to bring high-grade technology consulting to Alaskan companies through modern infrastructure and design.</p>
                </div>
                </Container>

                {/* <Particals /> */}

                <section style={{ paddingTop: 100, paddingBottom: 100, backgroundColor: '#303030', color: 'white', boxShadow: '0px 0px 10px black',}}>
                    <Container>
                    <h1 style={{ textAlign: 'center' }} className="mb-5">Our process</h1>

                        <Row className="justify-content-center">   
                            <Col md={8} lg={2} className="mb-5" style={{ maxWidth: '80vw' }}> 
                            <Row>
                                <Col md={2} lg={12} style={{marginRight: '15px'}}>
                                    <Image src={ConsultingIcon} height={80} width={80} style={{textAlign: 'center', marginBottom: 10}}/>
                                </Col>
                                <Col>
                                    <h5>Consult</h5>
                                    <p>We believe the success of a project is defined early on. We listen and analyze your idea to help better define and hone it. This will become the vision statement, which guides the whole project.</p>
                                </Col>
                            </Row>
                            </Col>
                            <Col md={8} lg={2} className="mb-5" style={{ maxWidth: '80vw' }}>
                                <Row>
                                    <Col md={2} lg={12} style={{marginRight: '15px'}}>
                                        <Image src={DesignIcon} height={80} width={80} style={{ textAlign: 'center', marginBottom: 10}}/>
                                    </Col>
                                    <Col>
                                        <h5>Design</h5>
                                        <p>Design is more than a set of graphic elements. It’s the process of solving a problem. This requires planning, research and strategizing. We then create wire frames and visual mock-ups.</p>
                                    </Col>
                                </Row>
                            </Col>

                            <Col md={8} lg={2} className="mb-5" style={{ maxWidth: '80vw' }}>
                                <Row>
                                    <Col md={2} lg={12} style={{marginRight: '15px'}}>
                                        <Image src={ImplementIcon} height={80} width={80} style={{textAlign: 'center', marginBottom: 10}}/>
                                    </Col>
                                    <Col>
                                        <h5>Implement</h5>
                                        <p>We then take the final mock-ups and turn them into partially functional prototypes that allow us to gain feedback as we progress through the coding process.</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={8} lg={2} className="mb-5" style={{ maxWidth: '80vw' }}>
                                <Row>
                                    <Col md={2} lg={12} style={{ marginRight: '15px' }}>
                                        <Image src={TestIcon} height={80} width={80} style={{textAlign: 'center', marginBottom: 10}}/>
                                    </Col>
                                    <Col>
                                        <h5>Test</h5>
                                        <p>Once all functionality is implemented, we move into testing. We test, retest and test again to make sure everything functions and is up to our high standards.</p>
                                    </Col>
                                </Row>
                            </Col>
                            
                            <Col md={8} lg={2} className="mb-5" style={{ maxWidth: '80vw' }}>
                                <Row>
                                    <Col md={2} lg={12} style={{marginRight: '15px'}}>
                                        <Image src={DeployIcon} height={80} width={80} style={{textAlign: 'center', marginBottom: 10}}/>
                                    </Col>
                                    <Col>
                                        <h5>Deploy</h5>
                                        <p>As soon as everything is perfect, we can deploy the app! Now it's time to sit back and enjoy the champagne.</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <Container style={{maxWidth: '800px', width: '80%'}}>
                <h1 style={{textAlign: 'center'}} className="text-muted mt-5">
                    Our Services
                </h1>
                <Row style={{ marginBottom: 100 }}>
                    { services_data.map((service, index) => { 
                        console.log(service.active)
                        if(service.active === true) {
                            return serviceCell(service, index) 
                        }
                    })}
                </Row>
            </Container>

            <PayStructureSection />

            <CompanyLoctionSection />

            <ContactUsSection />
            </>
        );
}

export default HomeScreen;