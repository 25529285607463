import React from 'react';
import {  } from 'formik';

import { Container } from 'react-bootstrap';



const JobDescriptionScreen = () => {

    return(
        <Container>
           


        </Container>
    );
    
}

export default JobDescriptionScreen;