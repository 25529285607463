import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';


// Secitons

class Footer extends Component {

    social = [
        { title: "linkedIn", link: "https://www.linkedin.com/company/67978345", icon: ""},
        { title: "Facebook", link: "", icon: ""}
    ]

    data = [
        {
            title: "Contact", 
            sections: [
                { title: "LinkedIn", external_link: "https://www.linkedin.com/company/67978345" },
                { title: "Call", external_link: "tel: 907.602.2776" },
                { title: "Email", external_link: "mailto: contact@sparrowdevelopment.net" },
            ]
        },
        {
            title: "Pages",
            sections: [
                { title: "Home", internal_link: "/"},
                { title: "About", internal_link: "/about"}
            ]
        },
        {
            title: "Legal",
            sections: [
                {title: "Privacy Policy", internal_link: '/privacy-policy'},
                {title: "Terms and Conditions", internal_link: '/terms-and-conditions'},
                {title: "GDPR - Data Privacy", internal_link: "/gdpr"}
            ]
        }
    ]


    handleLink(item) {
        if (item.internal_link != null) { 
            return(
             <Link to={item.internal_link} style={{color: '#515154'}} className="thin">
                 <p style={{
                     textAlign: "center",
                     fontSize: 14
                    }}>
                    {item.title}</p>
             </Link>
            );
        } else if (item.external_link != null) {
            return(
                <a href={item.external_link} style={{color: '#515154'}} target="_blank">
                    <p style={{textAlign: "center", fontSize: 14}}>{item.title}</p>
                </a>
            );
        } else {
            console.log("No link for some reason")
        }
    }

    render() {
        return(
            <footer 
                style={{
                    backgroundColor: '#F5F5F7',
                    height: '60px',
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    padding: 0
                }}>
                <Row className="justify-content-center" style={{textAlign: 'center'}}> 
                    <Col xs={12}>
                        <a href="mailto:contact@sparrowdevelopment.net" className="fa fa-envelope mr-3"></a>
                        <a href="https://www.facebook.com/sparrowdevelopment" target="_blank" className="fa fa-facebook mr-3"></a>
                        <a href="https://www.linkedin.com/company/sparrow-development/" target="_blank" className="fa fa-linkedin mr-3"></a>
                        <span style={{ margin: 0, fontWeight: 500 }} className="text-muted">&copy; Sparrow Development 2020</span>

                    </Col>
                </Row>
            </footer>
        ); 
    }
}

export default Footer;