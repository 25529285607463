export const industries_data = [
    {
        "title": "Coffee",
        "active": true,
        "link": "/industries/coffee"
    },
    {
        "title": "Canabis",
        "active": true,
        "link": "/industries/canabis"
    }
]